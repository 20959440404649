@import '../../colors.scss';

footer {
  text-align: center;
  padding-bottom: 15px;
  padding-top: 200px;

  .icons {
    margin-bottom: 15px;

    img {
      margin-right: 12px;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 18px;
  }

  h4 {
    text-transform: uppercase;
  }
}