@import '../../colors.scss';

.week {
  margin-top: 150px;

  h2 {
    text-align: center;
  }

  .line {
    width: 80%;
    background: #5811CB;
    height: 3px;
    text-align: center;
    top: 30px;
    left: 0;
    right: 0;
    z-index: -1;
    margin: 0 auto;
    position: absolute;
  }

  .steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    margin-top: 60px;
    position: relative;

    p {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
    }

    .step {
      text-align: center;
      position: relative;

      p {
        margin-top: 20px;
      }

      .circle {
        text-align: center;
        margin: 0 auto;
        width: 60px;
        height: 60px;
        border-radius: 50px;
        background: $purple;
        color: $white;
        font-weight: 800;
        font-size: 36px;
        position: relative;
      }
    }
  }
}

@media (max-width: 992px) {
  .week {
    .steps {
      grid-template-columns: 1fr 1fr;
      gap: 85px 50px;

      .step {
        display: grid;
        grid-template-columns: 20% 90%;

        .circle {
          margin: unset;
        }

        p {
          text-align: left;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    .line {
      display: none;
    }
  }
}

@media (max-width: 767.98px) {
  .week {
    .steps {
      .step {
        grid-template-columns: 30% 90%;

        p {
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .week {
    position: relative;

    .line {
      display: block;
      width: 3px;
      height: 100%;
      top: 0;
      left: 29px;
      margin: 0;
    }

    .steps {
      grid-template-columns: 1fr;
      text-align: left;
      position: relative;

      .step {
        text-align: left;
        display: grid;
        margin-bottom: 75px;
        grid-template-columns: 20% 90%;
        position: static;

        &:nth-child(4) {
          margin: 0;
        }

        .circle {
          margin: 0;
        }

        p {
          font-size: 20px;
          line-height: 24px;
          margin: 10px 0 0 0;
        }
      }
    }
  }
}