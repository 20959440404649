@import '../../colors.scss';

.difference {
  margin-top: 150px;

  h2 {
    text-align: center;
    padding-bottom: 40px;
  }
}

@media (max-width: 575.98px) {
  .difference {
    margin-top: 80px;

    h2 {
      padding-bottom: 0;
    }
  }
}