@import '../../colors.scss';

.mainBanner {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 0 20px;
  grid-template-areas: "text image";

  .bannerText {
    color: $black;
    margin-top: 200px;
    grid-area: text;

    h1 {
      font-weight: 800;
      font-size: 60px;
      margin-bottom: 15px;
      line-height: 100%;
    }

    h2 {
      font-weight: 300;
      font-size: 30px;
      margin-bottom: 15px;
      line-height: 37px;
      text-transform: uppercase;
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }

    button {
      width: 264px;
      height: 54px;
      font-weight: 600;
      background: $purple;
      color: $white;
      border-radius: 8px;
      border: none;
      font-size: 18px;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .bannerImage {
    grid-area: image;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .mainBanner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 0;
    margin-bottom: 80px;
    grid-template-areas:
            "image"
            "text";

    .bannerText {
      color: #121212;
      margin-top: -150px;
      padding-top: 80px;
      grid-area: text;
      z-index: 5;
      background: linear-gradient(1.53deg, #D3D3D3 65.69%, rgba(211, 211, 211, 0) 98.67%);
    }
  }
}

@media (max-width: 575.98px) {
  .mainBanner {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 0;
    grid-template-areas:
            "image"
            "text";

    .bannerText {
      color: #121212;
      margin-top: -150px;
      padding-top: 80px;
      grid-area: text;
      z-index: 5;
      background: linear-gradient(1.53deg, #D3D3D3 65.69%, rgba(211, 211, 211, 0) 98.67%);

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        line-height: 100%;
      }

      p {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}