@import '../../colors.scss';

.support {
  margin-top: 150px;

  .mobileImg {
    display: none;
  }

  h2 {
    text-align: center;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: $purple;
  }
}

@media (max-width: 992px) {
  .support {
    margin-top: 150px;

    .imageContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;

      img {
        height: 350px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .support {
    margin-top: 150px;

    .imageContainer {
      img {
        height: 100%;
        width: 320px;
      }
    }

    h2 {
      text-align: left;
    }

    h3 {
      text-align: left;
      font-size: 16px;
      line-height: 20px;
    }

    .desktopImg {
      display: none;
    }

    .mobileImg {
      display: block;

    }
  }
}