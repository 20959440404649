@import '../../colors.scss';

.course {
  .mainBanner {
    grid-template-columns: 1fr 1fr;
    gap: 0 20px;

    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }

    button {
      width: unset;
      padding: 0 20px;
      font-weight: 600;
    }

    .price {
      background: $white;
      padding: 30px;
      box-shadow: 0 4px 28px rgba(0, 0, 0, 0.15), inset 0px -6px 0px #5811CB;
      border-radius: 8px;
      margin-top: 40px;

      h3 {
        font-weight: bold;
        font-size: 24px;

        img {
          margin-right: 12px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .course {
    .mainBanner {
      grid-template-columns: 1fr;
      gap: 0 20px;
    }
  }
}

@media (max-width: 575.98px) {
  .course {
    .mainBanner {
      grid-template-columns: 1fr;
      gap: 0 20px;

      .price {
        h3 {
          font-size: 20px;
          line-height: 24px;

          &:first-child {
            border-bottom: 2px solid #E6E6E6;
            padding-bottom: 25px;
          }

          &:last-child {
            padding-top: 25px;
          }
        }
      }

      h1 {
        font-size: 36px;
        line-height: 100%;
      }

      h2 {
        font-size: 16px;
        line-height: 20px;
      }

      button {
        font-size: 16px;
        line-height: 13px;
      }
    }
  }
}